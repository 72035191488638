import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Card,
  Title,
  CardGrid,
  Section,
  Link,
  Button,
  LogoGrid,
  LogoGridLogo,
  Post,
  SitemapRenderer,
  SitemapEntryRenderer,
  Icon,
} from "@lleed-partners/factor";
import {
  ArrowRight32,
  ChatLaunch32,
  Launch32,
  Chat32,
  EventsAlt32,
  LogoGithub32,
} from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Layout } from "../layouts";
import { Fragment } from "react";
import { capitalize, trail } from "../fragments";

export default ({
  data: {
    pages: { nodes: pages },
  },
}) => {
  const { t } = useI18next();

  let runningCategory = "";

  return (
    <Layout>
      <LeadSection
        padTop
        inline
        title={t("Sitemap")}
        subtitle={t("List of all pages available in your current language.")}
        cta={
          <Button
            as={GatsbyLink}
            to="/"
            rightIcon={<Icon icon={<ArrowRight32 />} />}
          >
            {t("Get back home")}
          </Button>
        }
      />
      <SitemapRenderer>
        {pages.map((page) => {
          const currentCategory =
            page?.parentMdx?.frontmatter?.category || "other";

          let showCategory = false;

          if (currentCategory !== runningCategory) {
            showCategory = true;
            runningCategory = currentCategory;
          }

          /* i18next-extract-disable */
          return (
            <Fragment key={page.context.i18n.originalPath}>
              {showCategory ? (
                <SitemapEntryRenderer isCategory>
                  {capitalize(t(currentCategory))}
                </SitemapEntryRenderer>
              ) : null}
              <SitemapEntryRenderer>
                <Link
                  as={GatsbyLink}
                  to={trail(page.context.i18n.originalPath)}
                >
                  {trail(page.context.i18n.originalPath)}
                </Link>
              </SitemapEntryRenderer>
            </Fragment>
          );
          /* i18next-extract-enable */
        })}
      </SitemapRenderer>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pages: allSitePage(
      sort: { fields: [parentMdx___frontmatter___category, path], order: ASC }
      filter: {
        context: { i18n: { language: { eq: $language }, routed: { eq: true } } }
      }
    ) {
      nodes {
        parentMdx {
          frontmatter {
            category
          }
        }
        context {
          i18n {
            originalPath
          }
        }
      }
    }
  }
`;
